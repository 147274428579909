import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { IAuthContext, IAuthContextProvider } from '../interfaces/AuthContext';
import { ISignon, initialSignon } from '../interfaces/Login';
import LayoutComp from '../components/LayoutComp';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { setModules } from '../redux/slices/appSlice';

const AuthContext = createContext<IAuthContext>({
	isAuthenticated: false,
	login: (login: ISignon) => { },
	logout: () => { },
	data: initialSignon,
	package_id: null
});

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthContextProvider({ children }: IAuthContextProvider) {
	const dispatch = useDispatch();
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [data, setData] = useState<ISignon>(initialSignon);
	const [packageId, setPackageId] = useState<number | null>(null);

	// Verificar si hay cookies de autenticación
	useEffect(() => {
		const storedDataString = Cookies.get('authData');
		if (storedDataString) {
			try {
				const storedData = JSON.parse(storedDataString) as ISignon;
				setData(storedData);
				setIsAuthenticated(true);
			} catch (error) {
				console.error('Error al parsear la cookie:', error);
			}
		} else {
			console.log('No se encontró la cookie');
		}
	}, []);

	const login = (login: ISignon) => {
		 // Copia profunda del objeto login para evitar modificar el original
		 const loginWithoutPermissions = { ...login };
		setData(loginWithoutPermissions);
		setIsAuthenticated(true);
		Cookies.set('authData', JSON.stringify(loginWithoutPermissions), { expires: 7 }); // Guardar cookie por 7 días
	};

	const logout = () => {
		setData(initialSignon);
		setIsAuthenticated(false);
		Cookies.remove('authData');
		// Limpiar datos de redux
		dispatch(setModules(null));
	};

	/**
	 * Utilizamos useMemo para memorizar el objeto de contexto 
	 * que se pasa al proveedor de contexto (AuthContext.Provider), para evitar que 
	 * se vuelva a renderizar el proveedor y todos sus componentes hijos cada vez que 
	 * cambie el estado de autenticación.
	 */
	const authContextValue = useMemo(() => {
		return { isAuthenticated, login, logout, data, package_id: packageId };
	}, [isAuthenticated, data, packageId]);

	return (
		<AuthContext.Provider value={authContextValue}>
			{
				isAuthenticated ? <LayoutComp>
					{children}
				</LayoutComp> : children
			}
		</AuthContext.Provider>
	);
};
