import { IChief } from '../../interfaces/model/Chief';
import { IModule } from '../../interfaces/model/Module';
import { ITicket } from '../../interfaces/model/Ticket';

// Define el tipo del estado de la aplicación
export interface AppState {
    tickets: ITicket[] | null;
    ticketSelected: ITicket | null;
    modules: IModule[] | null;
    moduleSelected: IModule | null;
    employees: IModule[] | null;
    employeeSelected: IModule | null;
    chiefs: IChief[] | null;
    chiefSelected: IChief | null;
}

// Define el estado inicial de la aplicación
export const initialState: AppState = {
    modules: null,
    moduleSelected: null,
    tickets: null,
    ticketSelected: null,
    employees: null,
    employeeSelected: null,
    chiefs: null,
    chiefSelected: null
};
