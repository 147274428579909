import { FC } from 'react';
import { Button, IconButton, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface ButtonIconProps {
    type?: 'button' | 'reset' | 'submit';
    icon?: any;
    positionIcon?: 'left' | 'right'
    name?: string;
    variant?: 'solid' | 'outline' | 'ghost' | 'link';
    color?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    tooltip?: string;
    onClick?: () => void;
    disabled?: boolean;
    loading?: boolean;
    loadingText?: string;
    fullWidth?: boolean;
}

const ButtonIcon: FC<ButtonIconProps> = ({
    type = 'button',
    icon = faInfoCircle,
    positionIcon = 'left',
    name,
    size = 'sm',
    variant = 'outline',
    color = 'gray',
    tooltip,
    onClick = () => { },
    disabled = false,
    loading = false,
    loadingText = '',
    fullWidth = false,
    ...rest
}) => {
    return (
        <Tooltip label={tooltip} isDisabled={!tooltip}>
            {
                name ? <Button
                    {...rest}
                    type={type}
                    variant={variant}
                    colorScheme={color}
                    size={size}
                    onClick={onClick}
                    disabled={disabled}
                    width={fullWidth ? '100%' : undefined}
                    leftIcon={positionIcon === 'left' ? <FontAwesomeIcon icon={icon} /> : undefined}
                    rightIcon={positionIcon === 'right' ? <FontAwesomeIcon icon={icon} /> : undefined}
                    isLoading={loading}
                    loadingText={loadingText}
                >
                    {name}
                </Button> :
                    <IconButton
                        {...rest}
                        aria-label={loadingText}
                        type={type}
                        variant={variant}
                        colorScheme={color}
                        size={size}
                        onClick={onClick}
                        disabled={disabled}
                        isLoading={loading}
                        icon={<FontAwesomeIcon icon={icon} />}
                    />
            }

        </Tooltip>
    );
}

export default ButtonIcon;
