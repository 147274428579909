import CustomTitle from "../../components/CustomTitle";

type Props = {};

const EmployeesMain = (props: Props) => {
  return (
    <div>
      <CustomTitle title={"Empleados"} />
    </div>
  );
};

export default EmployeesMain;
