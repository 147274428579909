import CustomTitle from '../../components/CustomTitle';

const Dashboard = () => {
    return (
        <>
            <CustomTitle
                title='Dashboards'
            />
        </>
    );
}

export default Dashboard;