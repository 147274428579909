import { useState } from 'react';
import { Menu } from 'antd'
import { Link } from 'react-router-dom';
import { useAuth } from '../router/AuthContext';
import useConfirm from '../hooks/useConfirm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faHome, faSignOutAlt, faTicketAlt, faTicketSimple, faUser, faUserLock, faUsers } from '@fortawesome/free-solid-svg-icons';

const menu = [
	{
		key: '1',
		label: 'Inicio',
		icon: faHome,
		route: '/home'
	},
	{
		key: '2',
		label: 'Tickets',
		icon: faTicketAlt,
		route: '/tickets'
	},
	{
		key: '3',
		label: 'Vales',
		icon: faTicketSimple,
		route: '/vouchers'
	},
	{
		key: '3',
		label: 'Empleados',
		icon: faUsers,
		route: '/employees'
	},
	{
		label: 'Configuración',
		icon: faCog,
		route: '/settings'
	},
]

const footer_menu = [
	{
		label: 'Administrador',
		icon: faUserLock,
		route: '/settings'
	},
	{
		label: 'Salir',
		icon: faSignOutAlt,
	}
]

interface IMainSidebar {
	opencog: any;
}

const MainSidebar = ({ opencog }: IMainSidebar) => {
	const { logout, data } = useAuth();
	const { name } = data;
	const { showPromiseConfirm } = useConfirm();

	const [selectedKey, setSelectedKey] = useState<string>('item-menu-/home-0');
	const confirmLogout = () => {
		showPromiseConfirm({
			title: 'Cerrar sesión',
			content: '¿Está seguro que desea salir del sistema de tickets?',
			okText: 'Cerrar sesión',
			actionCallback: async () => {
				await new Promise(resolve => setTimeout(resolve, 1000));
				logout();
			}
		});
	}

	const handleSelect = (event: any) => setSelectedKey(event.key);

	return (
		<>
			<Menu
				theme={"dark"}
				mode="inline"
				selectedKeys={[selectedKey]}
				onSelect={handleSelect}
				style={{ borderRight: 0, paddingTop: 4 }}
			>
				<Menu.Item
					key={`item-menu-profile`}
					icon={<FontAwesomeIcon icon={faUser} />}
				>
					<Link to={'/profile'}>
						<h1 className='list-item-meta-title'>Tickets</h1>
						<h1 className='list-item-meta-description'>{name}</h1>
					</Link>
				</Menu.Item>

			</Menu>
			<Menu
				theme={"dark"}
				mode="inline"
				style={{ height: '100%', borderRight: 0, paddingTop: 4 }}
				selectedKeys={[selectedKey]}
				onSelect={handleSelect}
			>
				{
					menu.map((item, index) =>
						<Menu.Item
							key={`item-menu-${item?.route}-${index}`}
							icon={<FontAwesomeIcon icon={item.icon} />}
						>
							<Link to={item.route}>{item.label}</Link>
						</Menu.Item>
					)
				}
			</Menu>
			<Menu
				theme="dark"
				mode="inline"
				selectedKeys={[selectedKey]}
				onSelect={handleSelect}
				style={{ height: '100%', borderRight: 0, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}
			>
				{
					footer_menu.map((item, index) => (
						<Menu.Item
							key={`item-bottom-${item?.route ?? item?.label}-${index}`}
							onClick={() => index ? confirmLogout() : opencog()}
							icon={<FontAwesomeIcon icon={item.icon} />}
						>
							{
								item.route ?
									<Link to={item.route ?? '/'}>
										<span>  &nbsp;
											<span>{item.label}</span>
										</span>
									</Link> :
									<span>  &nbsp;
										<span>{item.label}</span>
									</span>
							}
						</Menu.Item>
					))
				}
			</Menu>
		</>
	)
}

export default MainSidebar