import { ReactNode } from 'react'
import { Modal } from 'antd'

interface IModalComp {
    title: string;
    body: ReactNode;
    onClose: () => void;
    loading?: boolean;
    visible?: boolean;
    footer?: ReactNode;
    maskClosable?: boolean;
}

const ModalComp = ({
    title,
    onClose,
    body,
    loading = false,
    visible = true,
    footer = <></>,
    maskClosable = false
}: IModalComp) => {
    return (
        <Modal
            style={{ top: 0 }}
            title={title}
            footer={footer}
            open={visible}
            loading={loading}
            onCancel={onClose}
            maskClosable={maskClosable}
        >
            {body}
        </Modal>
    )
}

export default ModalComp