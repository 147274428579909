import { useState } from "react";

interface IUseLoading {
    defaultLoading?: boolean;
}

const useLoading = ({ defaultLoading = false }: IUseLoading) => {
    const [isLoading, setIsLoading] = useState<boolean>(defaultLoading);

    const start = () => setIsLoading(true);

    const end = () => setIsLoading(false);

    return {
        isLoading,
        start,
        end
    }
}

export default useLoading;