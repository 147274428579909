import axios, { AxiosRequestConfig, AxiosError, CancelTokenSource } from "axios";
import { IFetcher } from "../interfaces/Fetcher";

const axiosAPIInstance = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER}`,
});

export const STATUS_OK = 200;
export const STATUS_CREATED = 201;

const fetcher = async (args: AxiosRequestConfig): Promise<IFetcher | undefined> => {
    const source: CancelTokenSource = axios.CancelToken.source();

    try {
        const response = await axiosAPIInstance({
            ...args,
            cancelToken: source.token,
        });

        return {
            fetch: response,
            cancel: () => source.cancel("Solicitud cancelada"),
        };
    } catch (error: any) {
        if (axios.isCancel(error)) {
            console.log("La petición fue cancelada:", error.message);
        } else {
            const axiosError = error as AxiosError;
            if (axiosError.response && axiosError.response.status === 401) {
                console.log("Cerrando sesión!");
            }
            return { fetch: (axiosError.response ?? axiosError.request) || axiosError.message, cancel: () => { } };
        }
    }
    throw new Error("Unexpected code execution");
};

export default fetcher;