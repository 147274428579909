import { useEffect } from "react";
import usePagination from "./usePagination";
import { indexVouchers } from "../api/main/vouchers";
import { IVoucher } from "../interfaces/model/Voucher";

const useVouchers = () => {
	const hookTickets = usePagination<IVoucher>({ pageSize: 20 });
	const { page, pageSize } = hookTickets;

	useEffect(() => {
		const getVouchers = async () => {
			hookTickets.loadingStart();
			const { data, totalItems } = await indexVouchers({ page, pageSize });
			hookTickets.setData(data);
			hookTickets.setTotalItems(totalItems);
			hookTickets.loadingEnd();
		};
		getVouchers();
	}, [page, pageSize]);

	return hookTickets;
};

export default useVouchers;
