import { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Form, FormControl, ListGroup, Overlay, Popover } from 'react-bootstrap'

const AutoCompleteComp = (props) => {
    const { options, onSelect, title, error, name, value, placeholder, handleChange, autoComplete } = props;
    const [showResults, setShowResults] = useState(false);

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(value?.toLowerCase())
    );

    const handleInputChange = event => {
        const { value } = event.target;
        handleChange(event);
        if (value !== '') setShowResults(true);
        else setShowResults(false);
    };

    const handleSelect = selectedOption => {
        // handleChange({ target: { name, value: selectedOption.label } });
        setShowResults(false);
        onSelect(selectedOption);
    };

    return (
        <Form.Group as={Col} className='mt-3'>
            {
                title && <Form.Label>{title}</Form.Label>
            }
            <FormControl

                id={`layout-autocomplete-${name}`}
                type="text"
                name={name}
                value={value}
                onChange={handleInputChange}
                placeholder={placeholder}
                autoComplete={autoComplete || 'off'}
            />
            <Overlay
                show={showResults && filteredOptions.length > 0}
                target={document.querySelector(`#layout-autocomplete-${name}`)}
                placement="bottom"
            >
                <Popover id="layout-autocomplete-popover">
                    <ListGroup>
                        {filteredOptions.map((option) => (
                            <ListGroup.Item
                                key={option.id}
                                action
                                onClick={() => handleSelect(option)}
                            >
                                {option.label}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Popover>
            </Overlay>
            {
                error && <Form.Label className="text-danger">
                    Dato inválido
                </Form.Label>
            }

        </Form.Group>
    );
};

AutoCompleteComp.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired
    })).isRequired,
    onSelect: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.bool,
    autoComplete: PropTypes.oneOf(['on', 'off'])
}

export default AutoCompleteComp