import { Navigate, useLocation } from 'react-router-dom';
import { IRequireAuth } from '../interfaces/AuthContext';
import { useAuth } from './AuthContext';

export function RequireAuth({ children }: IRequireAuth) {
	const { isAuthenticated } = useAuth();
	const location = useLocation();

	if (!isAuthenticated) {
		// Rediríjalos a la página de inicio de sesión, 
		// pero guarda la ubicación actual a la que intentaban 
		// ir cuando fueron redirigidos. Esto nos permite enviarlos 
		// a esa página después de iniciar sesión, lo que es una 
		// experiencia de usuario más agradable que dejarlos en la página de inicio.
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
}
