import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import usePagination from "./usePagination";
import { RootState } from "../redux/store";
import { logDebug } from "../libs/logs";
import { setEmployees } from "../redux/slices/appSlice";
import { indexChiefs } from "../api/auth/chiefs";
import { IChief } from "../interfaces/model/Chief";

const useChiefs = () => {
  const chiefsStored = useSelector((state: RootState) => state.app.chiefs);
  const dispatch = useDispatch();
  const hookChiefs = usePagination<IChief>({ pageSize: 1000 });
  const { page, pageSize } = hookChiefs;

  useEffect(() => {
    const getChiefs = async () => {
      hookChiefs.loadingStart();
      const { data, totalItems } = await indexChiefs({ page, pageSize });
      dispatch(setEmployees(data));
      hookChiefs.setData(data);
      hookChiefs.setTotalItems(totalItems);
      hookChiefs.loadingEnd();
    };
    if (chiefsStored) {
      logDebug("Empleados jefes cargados del store");
      hookChiefs.loadingStart();
      hookChiefs.setData(chiefsStored);
      hookChiefs.loadingEnd();
    } else {
      logDebug("Empleados jefes cargados de la api");
      getChiefs();
    }
  }, []);

  return hookChiefs;
};

export default useChiefs;
