import { configureStore } from '@reduxjs/toolkit';
import appReducer from './slices/appSlice';
import signonReducer from './slices/signonSlice';

// Combina los reducers en un único reducer raíz
const rootReducer = {
    app: appReducer,
    signon: signonReducer
};

// Configura el store de Redux
const store = configureStore({
    reducer: rootReducer,
});

export default store;

// Exporta el tipo RootState
export type RootState = ReturnType<typeof store.getState>;
