import React, { useEffect, useState } from "react";
import ModalComp from "../../components/ModalComp";
import AutoCompleteComp from "../../components/Autocomplete";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import ButtonIcon from "../../components/ButtonComp";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FormIcon from "../../components/FormIcon";
import { useAuth } from "../../router/AuthContext";
import { STATUS_CREATED } from "../../libs/fetcher";
import useNotification from "../../hooks/useNotification";
import useLoading from "../../hooks/useLoading";
import { Skeleton } from "antd";
import useEmployees from "../../hooks/useEmployees";
import useVouchers from "../../hooks/useVouchers";
import { storeVoucher } from "../../api/main/vouchers";
import { Row } from "react-bootstrap";
import useChiefs from "../../hooks/useChiefs";

type Props = {
    onClose: () => void;
    hookNotification: ReturnType<typeof useNotification>;
    hookVouchers: ReturnType<typeof useVouchers>;
    hookChiefs: ReturnType<typeof useChiefs>;
};

interface IOnSelect {
    id: number;
    label: string;
}

interface ICreateVoucher {
    name?: string;
    food_time: string;
    date?: string;
    folio?: string;
    shift?: string;
    authorized?: string;
    reason?: string;
}

const shiftData: { id: number, label: string }[] = [
    { id: 1, label: 'T.N' },
    { id: 2, label: 'T.V' },
    { id: 3, label: 'J.E' },
    { id: 4, label: 'T.M' },
];

const reasonData: { id: number, label: string }[] = [
    { id: 1, label: 'CUBRE SERVICIO' },
    { id: 2, label: 'SUPLENCIA' },
    { id: 3, label: 'INTERNO' },
    { id: 4, label: 'CAJA' }
];

const authorizedData: { id: number, label: string }[] = [
    { id: 1, label: 'JEFE DE ENF' },
    { id: 2, label: 'LETICIA TREJO' },
    { id: 3, label: 'DRA. GUADALUPE OLVERA' },
    { id: 4, label: 'CLARA HERNANDEZ' },
    { id: 5, label: 'ANTONIA NUÑEZ' },
    { id: 6, label: 'FINANZAS' },
    { id: 7, label: 'SERV. GENERALES' },
    { id: 8, label: 'ELIZABETH RODRIGUEZ' },
    { id: 9, label: 'GERARDA TREJO' },
    { id: 10, label: 'MERCEDES B VILLEGAS' },
    { id: 12, label: 'ROCIO PAREDES 0RDUÑA' },
    { id: 13, label: 'DR. LENIN' },
    { id: 14, label: 'LABORATORIO' },
    { id: 15, label: 'FLORENCIO' },
    { id: 16, label: 'L.E ARACELI ALVARADO' },
    { id: 17, label: 'FERMIN SILVA' },
    { id: 18, label: 'DRA. GLORIA' },
    { id: 19, label: 'DR. JESUS GOMEZ RODRIGUEZ' },
]

const ModalAdd = ({ onClose, hookNotification, hookVouchers, hookChiefs }: Props) => {
    const { data } = useAuth();
    const user_id = data.id;
    const [form, setForm] = useState<ICreateVoucher>({ food_time: 'DESAYUNO' });
    const [selectedOption, setSelectedOption] = useState<IOnSelect | null>(null);
    const hookLoading = useLoading({});

    useEffect(() => {
        if (selectedOption) {
            const currentChief = hookChiefs.data.find(employee => employee.id === selectedOption?.id)
            setForm(prevState => ({ ...prevState, name: currentChief?.name }));
        }
    }, [selectedOption]);

    const changeItem = (event: React.FormEvent<EventTarget>) => {
        const target = event.target as HTMLInputElement;
        const { name, value } = target;
        setForm(prevState => ({ ...prevState, [name]: value }));
    };

    const onSelect = (selectedOption: IOnSelect) => {
        setSelectedOption(selectedOption);
    }

    const onSave = async () => {
        hookLoading.start();
        const { food_time, date, name, folio, shift, reason, authorized } = form;

        const nameString = name ?? "";

        let id = undefined;
        if (selectedOption?.label === nameString) {
            id = selectedOption.id;
        }
        if (folio && reason && shift && authorized) {

            const dataPayload = {
                chief: {
                    id, name: nameString
                },
                user_id: user_id ?? 0,
                food_time,
                date: date ?? "",
                folio, shift, reason, authorized
            };

            const fetch = await storeVoucher({ data: dataPayload });
            if (fetch.status === STATUS_CREATED) {
                const { voucher, chief } = fetch.data;
                // Agregar vale a la tabla
                if (voucher) hookVouchers.addElement(voucher);
                // Si el empleado es nuevo, se agrega all hook
                if (chief) hookChiefs.addElement(chief);
                hookNotification.openNotification({ title: 'Vale agregado', description: 'El vale se ha agregado de forma correcta', type: 'success' });
                // Limpiar formulario
                setForm(prevState => ({ ...prevState, name: '', shift: '', reason: '', authorized: '', folio: '' }));
            } else {
                hookNotification.openNotification({ title: 'Ha ocurrido un problema', description: 'Error al tratar de agregar el ticket', type: 'error' });
            }
        }
        hookLoading.end();
    }

    const onSelectShift = (selectedOption: IOnSelect) => {
        setForm(prevState => ({ ...prevState, shift: selectedOption.label }));
    }

    const onSelectReason = (selectedOption: IOnSelect) => {
        setForm(prevState => ({ ...prevState, reason: selectedOption.label }));
    }

    const onSelectAuthorized = (selectedOption: IOnSelect) => {
        setForm(prevState => ({ ...prevState, authorized: selectedOption.label }));
    }

    return (
        <ModalComp
            title="Agregar Vale"
            onClose={onClose}
            maskClosable={false}
            body={
                <>
                    {
                        hookLoading.isLoading ? <Skeleton /> : <>
                            <FormIcon
                                title='Folio'
                                name='folio'
                                placeholder='Folio'
                                value={form?.folio}
                                handleChange={changeItem}
                                className='mt-3'
                            />
                            <AutoCompleteComp
                                title="Nombre de empleado"
                                name="name"
                                placeholder={"Nombre de empleado"}
                                options={hookChiefs.data.map((employee) => {
                                    const { id, name } = employee;
                                    return { id, label: name }
                                })}
                                value={form?.name ?? ''}
                                handleChange={changeItem}
                                onSelect={onSelect}
                            />
                            <Row>
                                <AutoCompleteComp
                                    title="Turno"
                                    name="shift"
                                    placeholder={"Turno"}
                                    options={shiftData}
                                    value={form?.shift ?? ''}
                                    handleChange={changeItem}
                                    onSelect={onSelectShift}
                                />
                                <AutoCompleteComp
                                    title="Motivo"
                                    name="reason"
                                    placeholder={"Motivo"}
                                    options={reasonData}
                                    value={form?.reason ?? ''}
                                    handleChange={changeItem}
                                    onSelect={onSelectReason}
                                />
                            </Row>
                            <AutoCompleteComp
                                title="Autorizó"
                                name="authorized"
                                placeholder={"Autorizó"}
                                options={authorizedData}
                                value={form?.authorized ?? ''}
                                handleChange={changeItem}
                                onSelect={onSelectAuthorized}
                            />
                            <Row>
                                <Form.Group as={Col} className="mt-3">
                                    <Form.Label>Tiempo de comida</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name="food_time"
                                        value={form?.food_time}
                                        onChange={changeItem}
                                    >
                                        <option value="DESAYUNO">DESAYUNO</option>
                                        <option value="COMIDA">COMIDA</option>
                                        <option value="CENA">CENA</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} className="mt-3">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control type="date" name="date" value={form?.date} onChange={changeItem} />
                                </Form.Group>
                            </Row>
                        </>
                    }
                </>
            }
            footer={
                <>
                    {
                        !hookLoading.isLoading &&
                        <ButtonIcon
                            name="Guardar"
                            icon={faPlus}
                            variant="solid"
                            color="teal"
                            onClick={onSave}
                        />
                    }
                </>
            }
        />
    );
};

export default ModalAdd;
