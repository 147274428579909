import React, { useEffect, useState } from "react";
import ModalComp from "../../components/ModalComp";
import AutoCompleteComp from "../../components/Autocomplete";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import ButtonIcon from "../../components/ButtonComp";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FormIcon from "../../components/FormIcon";
import useEmployees from "../../hooks/useEmployees";
import { storeTicket } from "../../api/main/tickets";
import { useAuth } from "../../router/AuthContext";
import { STATUS_CREATED } from "../../libs/fetcher";
import useNotification from "../../hooks/useNotification";
import useLoading from "../../hooks/useLoading";
import { Skeleton } from "antd";
import useTickets from "../../hooks/useTickets";

type Props = {
	onClose: () => void;
	hookNotification: ReturnType<typeof useNotification>;
	hookTickets: ReturnType<typeof useTickets>;
};

interface IOnSelect {
	id: number;
	label: string;
}

interface ICreateTicket {
	code?: string;
	name?: string;
	food_time: string;
	date?: string;
}

const ModalAdd = ({ onClose, hookNotification, hookTickets }: Props) => {
	const { data } = useAuth();
	const user_id = data.id;
	const [form, setForm] = useState<ICreateTicket>({ food_time: 'DESAYUNO' });
	const [selectedOption, setSelectedOption] = useState<IOnSelect | null>(null);
	const hookLoading = useLoading({});

	const hookEmployees = useEmployees();

	useEffect(() => {
		if (selectedOption) {
			const currentEmployee = hookEmployees.data.find(employee => employee.id === selectedOption?.id)
			setForm(prevState => ({ ...prevState, name: currentEmployee?.name, code: currentEmployee?.code }));
		}
	}, [selectedOption]);

	const changeItem = (event: React.FormEvent<EventTarget>) => {
		const target = event.target as HTMLInputElement;
		const { name, value } = target;
		setForm(prevState => ({ ...prevState, [name]: value }));
	};

	const onSelect = (selectedOption: IOnSelect) => {
		setSelectedOption(selectedOption);
	}

	const onSave = async () => {
		hookLoading.start();
		const { food_time, date, code, name } = form;

		const codeString = code ?? "";
		const nameString = name ?? "";

		let id = undefined;
		if (selectedOption?.label === `${codeString} | ${nameString}`) {
			id = selectedOption.id;
		}

		const dataPayload = {
			employee: {
				id, code: codeString, name: nameString,
			},
			user_id: user_id ?? 0,
			food_time,
			date: date ?? ""
		};

		const fetch = await storeTicket({ data: dataPayload });
		if (fetch.status === STATUS_CREATED) {
			const { ticket, employee } = fetch.data;
			// Agregar ticket a la tabla
			if (ticket) hookTickets.addElement(ticket);
			// Si el empleado es nuevo, se agrega all hook
			if(employee) hookEmployees.addElement(employee);
			hookNotification.openNotification({ title: 'Ticket agregado', description: 'El ticket se ha agregado de forma correcta', type: 'success' });
			// Limpiar formulario
			setForm(prevState => ({ ...prevState, code: '', name: '' }));
		} else {
			hookNotification.openNotification({ title: 'Ha ocurrido un problema', description: 'Error al tratar de agregar el ticket', type: 'error' });
		}
		hookLoading.end();
	}


	return (
		<ModalComp
			title="Agregar ticket"
			onClose={onClose}
			body={
				<>
					{
						hookLoading.isLoading ? <Skeleton /> : <>
							<AutoCompleteComp
								title="Código de empleado"
								name="code"
								placeholder={"Código de empleado"}
								options={hookEmployees.data.map((employee) => {
									const { id, code, name } = employee;
									return { id, label: `${code} | ${name}` }
								})}
								value={form?.code ?? ''}
								handleChange={changeItem}
								onSelect={onSelect}
							/>
							<FormIcon
								title='Nombre de empleado'
								name='name'
								placeholder='Nombre del empleado'
								value={form?.name}
								handleChange={changeItem}
								className='mt-3'
							/>
							<Form.Group as={Col} className="mt-3">
								<Form.Label>Tiempo de comida</Form.Label>
								<Form.Select
									aria-label="Default select example"
									name="food_time"
									value={form?.food_time}
									onChange={changeItem}
								>
									<option value="DESAYUNO">DESAYUNO</option>
									<option value="COMIDA">COMIDA</option>
									<option value="CENA">CENA</option>
								</Form.Select>
							</Form.Group>
							<Form.Group as={Col} className="mt-3">
								<Form.Label>Fecha</Form.Label>
								<Form.Control type="date" name="date" value={form?.date} onChange={changeItem} />
							</Form.Group>
						</>
					}
				</>
			}
			footer={
				<>
					{
						!hookLoading.isLoading &&
						<ButtonIcon
							name="Guardar"
							icon={faPlus}
							variant="solid"
							color="teal"
							onClick={onSave}
						/>
					}
				</>
			}
		/>
	);
};

export default ModalAdd;
