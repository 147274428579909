import { ITicketIndexParams, ITicketIndexResponse, ITicketStoreParams, ITicketStoreResponse, ITicketDownloadParams, ITicketDownloadResponse } from "../../interfaces/model/Ticket";
import fetcher, { STATUS_CREATED } from "../../libs/fetcher";
import { logDebug } from "../../libs/logs";

export const indexTickets = async ({ page, pageSize }: ITicketIndexParams): Promise<ITicketIndexResponse> => {
    const url = `/main/tickets`;
    let response: ITicketIndexResponse = { data: [], totalItems: 0 };
    let params: any = { page, pageSize };
    try {
        const res = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (res === undefined) {
            throw new Error('Request failed');
        }
        const { fetch } = res;
        logDebug("🚀 ~ indexTickets ~ fetch:", fetch)
        if (typeof fetch === 'string') {
            // Manejar si fetch es cadena
        } else if (fetch instanceof Object) {
            if ('status' in fetch && fetch.status === 200) {
                const responseData = 'data' in fetch ? fetch.data : null;
                if (responseData) {
                    response = { data: responseData.data, totalItems: parseInt(responseData.totalItems) }
                }
            }
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const storeTicket = async ({ data }: ITicketStoreParams): Promise<ITicketStoreResponse> => {
    const url = `/main/tickets`;
    const response = { status: 500, data: { ticket: undefined, employee: undefined } }
    try {
        const res = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        console.log("🚀 ~ storeTicket ~ res:", res)
        if (res === undefined) {
            throw new Error('Request failed');
        }
        const { fetch } = res;

        if (typeof fetch === 'string') {
            // Manejar si fetch es cadena
        } else if (fetch instanceof Object) {
            if ('status' in fetch && fetch.status === STATUS_CREATED) {
                const responseData = 'data' in fetch ? fetch.data : null;
                response.data = responseData;
                response.status = fetch.status;
            }
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const downloadTickets = async ({ start_date, end_date }: ITicketDownloadParams): Promise<ITicketDownloadResponse> => {
    const url = `/main/tickets/download`;
    let response: ITicketDownloadResponse = { signedUrl: null};
    let params: any = { start_date, end_date };
    try {
        const res = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (res === undefined) {
            throw new Error('Request failed');
        }
        const { fetch } = res;
        logDebug("🚀 ~ downloadTickets ~ fetch:", fetch)
        if (typeof fetch === 'string') {
            // Manejar si fetch es cadena
        } else if (fetch instanceof Object) {
            if ('status' in fetch && fetch.status === 200) {
                const responseData = 'data' in fetch ? fetch.data : null;
                if (responseData) {
                    response = { ...responseData}
                }
            }
        }
        return response;
    } catch (error) {
        return response;
    }
}