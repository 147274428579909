import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IFetcher } from '../../interfaces/Fetcher';
import { useAuth } from '../../router/AuthContext';
import { signon } from '../../api/main/auth';
import { Checkbox, Col, Form, Input, Row, Typography } from "antd";

import { LockOutlined, UserOutlined } from "@ant-design/icons";

import ImageLogin from '../../images/login.jpg';
import ImageLogo from '../../images/logo.png';
import ButtonIcon from '../../components/ButtonComp';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import useLoading from '../../hooks/useLoading';

import { Center } from '@chakra-ui/react';
import LoginStyles from './styles';
import useNotification from '../../hooks/useNotification';

const { Text, Title } = Typography;

export function Login() {
    const { login, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const hookLoading = useLoading({ defaultLoading: false });
    const hookNotification = useNotification({ defaultPlacement: 'bottomLeft' });
    const { styles } = LoginStyles();

    const from = location.state?.from?.pathname || "/";

    useEffect(() => {
        if (isAuthenticated) navigate(from, { replace: true });
    }, [isAuthenticated]);

    const onFinish = (values: any) => {
        const { email, password } = values;
        const data = { email, password, app: 'W' }
        hookLoading.start();
        signon(data)
            .then((response) => {
                const { fetch } = response;
                handleResponse(fetch);
            })
            .catch(() => {
                hookNotification.openNotification({
                    type: 'error',
                    title: '¡Ha ocurrido evento inesperado!',
                    description: 'Inténtelo más tarde'
                });
            })
            .finally(() => {
                hookLoading.end();
            });
    };

    const handleResponse = (fetch: IFetcher['fetch']) => {
        if (typeof fetch === 'string') {
            // Manejar si fetch es cadena
        } else if (fetch instanceof Object) {
            if ('status' in fetch) {
                if (fetch.status === 200) {
                    const responseData = 'data' in fetch ? fetch.data : null;
                    if (responseData) {
                        login(responseData);
                    } else {
                        hookNotification.openNotification({
                            type: 'warning',
                            title: '¡Ha ocurrido un error!',
                            description: 'No se pudo obtener una respuesta, inténtelo más tarde.'
                        });
                    }
                } else if (fetch.status === 401) {
                    const responseData = 'data' in fetch ? fetch.data : null;
                    hookNotification.openNotification({
                        type: 'warning',
                        title: responseData.error,
                        description: responseData.message
                    });
                } else if (fetch.status === 0) {
                    hookNotification.openNotification({
                        type: 'error',
                        title: 'Error de comunicación',
                        description: 'Ha ocurrdio un error al tratar de realizar la comunicación con los servidores. Revise su conexión a internet'
                    });
                }
            }
        }
    };

    return (
        <Row gutter={[16, 16]} style={styles.section}>
            <Col xs={24} md={10} lg={9} xl={8} xxl={7} style={styles.colForm}>
                <div style={styles.container}>
                    <div className='login' id='header' style={styles.header}>
                        <Center>
                            <img src={ImageLogo} alt="Login illustration" style={styles.logo} />
                        </Center>
                        <Title style={styles.title}>Acceder</Title>
                        <Text style={styles.text}>
                            Bienvenido
                        </Text>
                    </div>

                    <Form
                        name="normal_login"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        layout="vertical"
                        requiredMark="optional"
                    >
                        <Form.Item
                            name="email"
                            label="Nombre de usuario"
                            rules={[
                                {
                                    required: true,
                                    message: "¡Ingrese su nombre de usuario!",
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Email"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Contraseña"
                            rules={[
                                {
                                    required: true,
                                    message: "¡Ingrese su contraseña!",
                                },
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                            <a style={styles.forgotPassword} href="/forgot-password">
                                Forgot password?
                            </a>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: "0px" }}>
                            <ButtonIcon
                                type='submit'
                                name='Inciar sesión'
                                color='blue'
                                variant='solid'
                                icon={faSignInAlt}
                                loading={hookLoading.isLoading}
                                loadingText='Obteniendo acceso...'
                                fullWidth
                            />
                            {hookNotification.contextHolder}
                        </Form.Item>
                    </Form>
                </div>
            </Col>
            <Col xs={24} md={14} lg={15} xl={16} xxl={16} style={styles.colImage}>
                <img src={ImageLogin} alt="Login illustration" style={styles.login} />
            </Col>
        </Row>
    );
}
