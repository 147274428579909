import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state/appState';

// Crea un slice de Redux para manejar el estado de la aplicación
const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setModules: (state, action) => {
            state.modules = action.payload;
        },
        setModuleSelected: (state, action) => {
            state.moduleSelected = action.payload
        },
        setTickets: (state, action) => {
            state.tickets = action.payload;
        },
        setTicketSelected: (state, action) => {
            state.ticketSelected = action.payload
        },
        setEmployees: (state, action) => {
            state.employees = action.payload;
        },
        setEmployeeSelected: (state, action) => {
            state.employeeSelected = action.payload
        },
        setChiefs: (state, action) => {
            state.chiefs = action.payload;
        },
        setChiefSelected: (state, action) => {
            state.chiefSelected = action.payload
        }
    },
});

// Exporta las acciones generadas por el slice
export const {
    setTickets,
    setTicketSelected,
    setModules,
    setModuleSelected,
    setEmployees,
    setEmployeeSelected,
    setChiefs,
    setChiefSelected
} = appSlice.actions;

// Exporta el reducer del slice
export default appSlice.reducer;
