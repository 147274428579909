import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import usePagination from "./usePagination";
import { RootState } from "../redux/store";
import { logDebug } from "../libs/logs";
import { setEmployees } from "../redux/slices/appSlice";
import { IEmployee } from "../interfaces/model/Employee";
import { indexEmployees } from "../api/auth/employees";

const useEmployees = () => {
  const employeesStored = useSelector((state: RootState) => state.app.employees);
  const dispatch = useDispatch();
  const hookEmployees = usePagination<IEmployee>({ pageSize: 1000 });
  const { page, pageSize } = hookEmployees;

  useEffect(() => {
    const getEmployees = async () => {
      hookEmployees.loadingStart();
      const { data, totalItems } = await indexEmployees({ page, pageSize });
      dispatch(setEmployees(data));
      hookEmployees.setData(data);
      hookEmployees.setTotalItems(totalItems);
      hookEmployees.loadingEnd();
    };
    if (employeesStored) {
      logDebug("Empleados cargados del store");
      hookEmployees.loadingStart();
      hookEmployees.setData(employeesStored);
      hookEmployees.loadingEnd();
    } else {
      logDebug("Empleados cargados de la api");
      getEmployees();
    }
  }, []);

  return hookEmployees;
};

export default useEmployees;
