import { notification } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface IUseNotification {
    defaultPlacement: NotificationPlacement
}

interface IOpenNotification {
    type?: NotificationType,
    title: string,
    description: string,
    placement?: NotificationPlacement
}

const useNotification = ({ defaultPlacement }: IUseNotification) => {
    const [api, contextHolder] = notification.useNotification();

    const openNotification = ({ type = 'info', title, description, placement }: IOpenNotification) => {
        api[type]({
            message: title,
            description: description,
            placement: defaultPlacement ?? placement,
        });
    };

    return {
        contextHolder,
        openNotification
    }
}

export default useNotification; 