import React from 'react'
import CustomTitle from '../../components/CustomTitle'
import ButtonIcon from '../../components/ButtonComp'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const ForgotPassword: React.FC = () => {
    const goBack = () =>  window.location.href = '/login';
    return (<>
        <CustomTitle
            title='Recuperación de contraseña'
            actions={
                <ButtonIcon icon={faArrowLeft} onClick={goBack} tooltip='Regresar'/>
            }
        />
        <div>Escribir formulario</div>
    </>)
}

export default ForgotPassword