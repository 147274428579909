import { faDownload } from "@fortawesome/free-solid-svg-icons";
import ButtonIcon from "../../components/ButtonComp";
import ModalComp from "../../components/ModalComp";
import FormIcon from "../../components/FormIcon";
import { useState } from "react";
import { ITicketDownloadParams } from "../../interfaces/model/Ticket";
import useLoading from "../../hooks/useLoading";
import { downloadTickets } from "../../api/main/tickets";
import useNotification from "../../hooks/useNotification";
import { Skeleton } from "antd";

type Props = {
	onClose: () => void;
	hookNotification: ReturnType<typeof useNotification>;
};

const ModalXlsx = ({ onClose, hookNotification }: Props) => {
	const hookLoading = useLoading({});
	const [form, setForm] = useState<ITicketDownloadParams>({});

	const changeItem = (event: React.FormEvent<EventTarget>) => {
		const target = event.target as HTMLInputElement;
		const { name, value } = target;
		setForm(prevState => ({ ...prevState, [name]: value }));
	};

	const onDownload = async () => {
		hookLoading.start();
		const { end_date, start_date } = form;
		if (end_date && start_date) {
			const { signedUrl } = await downloadTickets({ start_date, end_date });
			if (signedUrl) {
				const link = document.createElement('a')
				link.href = signedUrl;
				document.body.appendChild(link)
				link.click();
			} else {
				hookNotification.openNotification({ title: 'Enlace inválido', description: 'Enlace incorrecto', type: 'error' });
			}
		} else {
			hookNotification.openNotification({ title: 'Campos imcompletos', description: 'Llene el formulario', type: 'error' });
		}
		hookLoading.end();
	}

	return (
		<ModalComp
			title="Descargar reporte"
			onClose={onClose}
			body={
				<>
					{
						hookLoading.isLoading ? <Skeleton active /> : <>
							<FormIcon
								type='date'
								title='Fecha de inicio'
								name='start_date'
								value={form?.start_date}
								handleChange={changeItem}
								className='mt-3'
							/>
							<FormIcon
								type='date'
								title='Fecha final'
								name='end_date'
								value={form?.end_date}
								handleChange={changeItem}
								className='mt-3'
							/>
						</>
					}
				</>
			}
			footer={
				<>
					{
						!hookLoading.isLoading &&
						<ButtonIcon
							icon={faDownload}
							name="Descargar"
							variant="solid"
							color="teal"
							onClick={onDownload}
						/>
					}
				</>
			}
		/>
	);
};

export default ModalXlsx;
