import { IPackages, IPermisions } from "./Main";

export interface ILogin {
    email: string;
    password: string;
}

export interface IA2F {
    user_id: string;
    has_two_factor: boolean;
}

export interface ISignon {
    id: number | null,
    email: string,
    name: string,
}

export const initialLoginData: ILogin = {
    email: '',
    password: '',
}

export const initialPackage: IPackages = {
    id: -1,
    code: '',
    name: '',
    image: '',
    created_at: '',
    updated_at: ''
}

export const initialSignon: ISignon = {
    id: null,
    email: '',
    name: ''
}       