import React from 'react'
import ButtonIcon from './ButtonComp'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Avatar, Divider, List, Menu } from 'antd'
import { ToolOutlined, SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface IConfigSidebar {
    backHome: any;
}

const config_menu = [
    {
        key: '1',
        label: 'Generales',
        route: '/settings'
    },
    {
        key: '2',
        label: 'Perfil',
        route: '/profile'
    }

]

const ConfigSidebar = ({ backHome }: IConfigSidebar) => {
    return (
        <>
            <div className='div-header-1'>
                <ButtonIcon type='reset' onClick={backHome} name='Regresar' icon={faArrowLeft} fullWidth />
                <div className='div-header-2'>
                    <List
                        itemLayout="horizontal"
                        dataSource={[{ title: 'Configuración' }]}
                        renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar size="large" style={{ alignSelf: 'center' }}>
                                            <ToolOutlined />
                                        </Avatar>
                                    }
                                    title={
                                        <h1 style={{ fontSize: 12, fontWeight: '400', color: 'gray', lineHeight: 1 }}>{item.title}</h1>
                                    }
                                    description={
                                        <h1 style={{ fontSize: 18, fontWeight: 'bold', color: 'black', lineHeight: 1 }}>Tickets</h1>
                                    }

                                />
                            </List.Item>
                        )}
                    />
                    <Divider />
                </div>
            </div>
            <div className='div-header-1'>
                <div className='div-header-2'>
                    <div className='div-header-menu-1'>
                        <SettingOutlined style={{ fontSize: 20 }} />
                        <h1 style={{ fontSize: 17, fontWeight: '600' }}>Sistema</h1>
                    </div>
                </div>
            </div>
            <Menu theme={'light'} mode="inline" style={{ height: '100%', borderRight: 0, paddingTop: 4, paddingLeft: 24 }}>
                {
                    config_menu.map((item, index) =>
                        <Menu.Item key={`item-menu-cog-${item?.route}-${index}`}>
                            <Link to={item.route}>{item.label} </Link>
                        </Menu.Item>
                    )
                }
            </Menu>
        </>
    )
}

export default ConfigSidebar