import { IVoucherDownloadParams, IVoucherDownloadResponse, IVoucherIndexParams, IVoucherIndexResponse, IVoucherStoreParams, IVoucherStoreResponse } from "../../interfaces/model/Voucher";
import fetcher, { STATUS_CREATED } from "../../libs/fetcher";
import { logDebug } from "../../libs/logs";

export const indexVouchers = async ({ page, pageSize }: IVoucherIndexParams): Promise<IVoucherIndexResponse> => {
    const url = `/main/vouchers`;
    let response: IVoucherIndexResponse = { data: [], totalItems: 0 };
    let params: any = { page, pageSize };
    try {
        const res = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (res === undefined) {
            throw new Error('Request failed');
        }
        const { fetch } = res;
        logDebug("🚀 ~ indexVouchers ~ fetch:", fetch)
        if (typeof fetch === 'string') {
            // Manejar si fetch es cadena
        } else if (fetch instanceof Object) {
            if ('status' in fetch && fetch.status === 200) {
                const responseData = 'data' in fetch ? fetch.data : null;
                if (responseData) {
                    response = { data: responseData.data, totalItems: parseInt(responseData.totalItems) }
                }
            }
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const storeVoucher = async ({ data }: IVoucherStoreParams): Promise<IVoucherStoreResponse> => {
    const url = `/main/vouchers`;
    const response = { status: 500, data: { voucher: undefined, chief: undefined } }
    try {
        const res = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        logDebug("🚀 ~ storeVoucher ~ res:", res)
        if (res === undefined) {
            throw new Error('Request failed');
        }
        const { fetch } = res;

        if (typeof fetch === 'string') {
            // Manejar si fetch es cadena
        } else if (fetch instanceof Object) {
            if ('status' in fetch && fetch.status === STATUS_CREATED) {
                const responseData = 'data' in fetch ? fetch.data : null;
                response.data = responseData;
                response.status = fetch.status;
            }
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const downloadVouchers = async ({ start_date, end_date }: IVoucherDownloadParams): Promise<IVoucherDownloadResponse> => {
    const url = `/main/vouchers/download`;
    let response: IVoucherDownloadResponse = { signedUrl: null};
    let params: any = { start_date, end_date };
    try {
        const res = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (res === undefined) {
            throw new Error('Request failed');
        }
        const { fetch } = res;
        logDebug("🚀 ~ downloadVouchers ~ fetch:", fetch)
        if (typeof fetch === 'string') {
            // Manejar si fetch es cadena
        } else if (fetch instanceof Object) {
            if ('status' in fetch && fetch.status === 200) {
                const responseData = 'data' in fetch ? fetch.data : null;
                if (responseData) {
                    response = { ...responseData}
                }
            }
        }
        return response;
    } catch (error) {
        return response;
    }
}