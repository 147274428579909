import React from 'react';
import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';
import { IPaginationComponentProps } from '../interfaces/Pagination';

const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    console.log(current, pageSize);
};

const PaginationComp: React.FC<IPaginationComponentProps> = ({
    totalItems,
    pageSize,
    page,
    changePage
}) => (
    <div style={{ textAlign: 'right' }}>
        <Pagination
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            total={totalItems ?? 0}
            showTotal={total => `Total ${total} elementos`}
            defaultPageSize={pageSize ?? 10}
            pageSizeOptions={['10', '25', '50', '100']}
            defaultCurrent={1}
            current={page}
            onChange={changePage}
        />
    </div>
);

export default PaginationComp;