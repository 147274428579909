import { ReactNode, useState } from 'react'
import { Layout, theme } from 'antd';
import { useNavigate } from 'react-router-dom';
import ConfigSidebar from './ConfigSidebar';
import MainSidebar from './MainSidebar';

const { Content, Footer, Sider } = Layout;

type Props = {
    children: ReactNode
}

const LayoutComp = ({ children }: Props) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const navigate = useNavigate();
    const [menuCog, setMenuCog] = useState(true);
    const [collapsed, setCollapsed] = useState(false);

    const opencog = () => {
        setCollapsed(false);
        setMenuCog(!menuCog);
    }
    const backHome = () => {
        setMenuCog(true);
        navigate(`/`)
    }

    const onCollapse = (collapsed: boolean) => {
        setCollapsed(collapsed);
    }

    return (
        <Layout style={{ height: '100vh' }}>
            <Sider
                collapsible
                onCollapse={onCollapse}
                breakpoint="lg"
                collapsed={collapsed}
                style={{ backgroundColor: colorBgContainer, borderRightWidth: 0.5 }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: menuCog ? 'space-between' : undefined,
                    height: menuCog ? '100%' : undefined
                }}>
                    {
                        menuCog ?
                            <MainSidebar opencog={opencog} /> :
                            <ConfigSidebar backHome={backHome} />
                    }

                </div>
            </Sider>
            <Layout>
                {/* <Header style={{ padding: 0, background: colorBgContainer }} /> */}
                <Content style={{ margin: '24px 16px 0' }}>
                    <div
                    // style={{ padding: 24, height: '90vh', background: colorBgContainer }}
                    >
                        {children}
                    </div>
                </Content>
                {/* <Footer style={{ textAlign: 'center' }}>Sistema de Tickets</Footer> */}
            </Layout>
        </Layout >
    )
}

export default LayoutComp