import { ISignon, initialSignon } from '../../interfaces/Login';

// Define el tipo del estado de la aplicación
export interface SignonState {
   data: ISignon | null
}

// Define el estado inicial de la aplicación
export const initialState: SignonState = {
    data: initialSignon
};
