import { faFileExcel, faPlus } from "@fortawesome/free-solid-svg-icons";
import ButtonIcon from "../../components/ButtonComp";
import CustomTitle from "../../components/CustomTitle";
import  { useState } from "react";
import TableComp from "../../components/Table";
import PaginationComp from "../../components/PaginationComp";
import ModalAdd from "./ModalAdd";
import ModalXlsx from "./ModalXlsx";
import useTickets from "../../hooks/useTickets";
import useNotification from "../../hooks/useNotification";


const TicketsMain = () => {
    const [modalAdd, setModalAdd] = useState < boolean > (false);
    const [modalXlsx, setModalXlsx] = useState < boolean > (false);
    const hookTickets = useTickets();
    const hookNotification = useNotification({ defaultPlacement: 'topRight' });

    return (
        <div>
            {hookNotification.contextHolder}
            <CustomTitle
                title={"Tickets"}
                actions={[
                    <ButtonIcon name="Descargar reporte" icon={faFileExcel} variant="solid" color="green" onClick={() => setModalXlsx(true)} />,
                    <ButtonIcon name="Agregar ticket" icon={faPlus} variant="solid" color="teal" onClick={() => setModalAdd(true)} />
                ]}
            />
            <TableComp
                headers={['#', 'Código de empleado', 'Nombre de empleado', 'Tiempo de comida', 'Fecha de registro', 'Fecha de creación', 'Acciones']}
                keys={['#', 'employee.code', 'employee.name', 'food_time', 'date', 'created_at']}
                body={hookTickets.data}
                actions={[]}
                loading={hookTickets.loading}
                loadingItems={hookTickets.pageSize}
                page={hookTickets.page}
                moment_dates={[{ index: 4, format: 'LL' }, { index: 5, format: 'LLL' }]}
            />
            <PaginationComp
                changePage={hookTickets.changePage}
                page={hookTickets.page}
                pageSize={hookTickets.pageSize}
                totalItems={hookTickets.totalItems}
            />

            {
                modalAdd &&
                <ModalAdd
                    onClose={() => setModalAdd(false)}
                    hookNotification={hookNotification}
                    hookTickets={hookTickets}
                />
            }

            {
                modalXlsx &&
                <ModalXlsx
                    onClose={() => setModalXlsx(false)}
                    hookNotification={hookNotification}
                />
            }
        </div>
    );
};

export default TicketsMain;
