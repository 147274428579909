/**
 * 
 * @param {number} num Kilometro expresado en metros
 * @returns {string} Formato 000+000 para un número entero
 */
export const getKmFormat = (number) => {
    let response = number;                                         // Retorna formato desconocido en caso de una excepción
    try {
        if (!isNaN(number)) {                                              // Verificar que es un número, sólo admite enteros es decir, metros
            const zero = '0';                                             // Constante a ser reemplazada
            const numberLength = number.toString().length;                // Obtener la longitud 
            response = zero.repeat(6 - numberLength) + number;            // Llenar espacios en 0 para completar la longitud de 6
            return response.replace(/(\d{3})(\d{3})/, '$1+$2');         // Separar con signo +
        }
        return response;
    } catch (error) {
        return response;
    }
};

export const getKmNumbers = (km) => {
    let meter = 0, kilometer = 0;

    if (typeof km === 'number') {
        kilometer = Math.floor(km / 1000);
        meter = km % 1000;
    }

    if (typeof km === 'string') {
        if (km.includes('+')) {
            const [kmStr, mStr] = km.split('+');
            kilometer = parseInt(kmStr);
            meter = parseInt(mStr);
        } else {
            const kmNum = parseInt(km);
            kilometer = Math.floor(kmNum / 1000);
            meter = kmNum % 1000;
        }
    }

    return { meter, kilometer };
}

/**
 * 
 * @param {number} num Número a convertir
 * @param {number} accuracy Precisión, catidad de dígitos después del .
 * @returns {string} 
 */
export const getPercentaje = (num, accuracy) => {
    let response = '? %';
    try {
        response = Number(num).toLocaleString(
            undefined,
            { style: 'percent', minimumFractionDigits: accuracy }
        );
        return response;
    } catch (error) {
        return response;
    }
};

/**
 * 
 * @param {number} number 
 * @returns {string} Number with currency format
 */
export const getCurrency = (number, decimals = 2) => {
    const options = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    };
    const numberFormat = new Intl.NumberFormat('en-US', options);
    let numFormat = number;
    try {
        numFormat = numberFormat.format(number);
        if (numFormat === '$NaN') numFormat = number;
    } catch (error) {
        // console.log("format number error", error);
    }
    return numFormat;
}