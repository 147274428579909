import fetcher from "../../libs/fetcher";
import { IFetcher } from "../../interfaces/Fetcher";
import { IA2F, ILogin } from "../../interfaces/Login";

export const signon = async ({
    email,
    password
}: ILogin): Promise<IFetcher> => {
    try {
        const response = await fetcher({
            url: `/auth/signon`,
            method: 'POST',
            data: { email, password },
        });
        console.log("🚀 ~ file: auth.ts:17 ~ response:", response)
        if (response?.fetch === undefined) {
            throw new Error('Request failed');
        }
        const { fetch, cancel } = response;
        return { fetch, cancel };
    } catch (error: any) {
        return { fetch: error, cancel: () => { } };
    }
};

