import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

const { confirm } = Modal;

interface IShowPromiseConfirm {
    title: string,
    icon?: React.ReactNode,
    content: string,
    okText?: string,
    actionCallback: () => Promise<void>;
}
const useConfirm = () => {
    const showPromiseConfirm = ({
        title,
        icon = <ExclamationCircleFilled />,
        content,
        okText = 'Ok',
        actionCallback
    }: IShowPromiseConfirm) => {
        confirm({
            title,
            icon,
            content,
            okText,
            cancelText: 'Cancelar',
            async onOk() {
                try {
                    await actionCallback();
                } catch (error) {
                    console.error("Error during actionCallback:", error);
                }
            },
            onCancel() { },
        });
    };

    return {
        showPromiseConfirm
    }
}

export default useConfirm