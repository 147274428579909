import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state/signonState';

// Crea un slice de Redux para manejar el estado de la aplicación
const appSlice = createSlice({
    name: 'signon',
    initialState,
    reducers: {
        setSignon: (state, action) => {
            state.data = action.payload;
        },
        logout: (state) => {
            state.data = null;
        }
    },
});

// Exporta las acciones generadas por el slice
export const { setSignon, logout } = appSlice.actions;

// Exporta el reducer del slice
export default appSlice.reducer;
