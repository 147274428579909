import Title from 'antd/es/typography/Title';
import React from 'react';
import ButtonIcon from './ButtonComp';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Space } from 'antd';

interface ICustomTitle {
	title: string;
	actions?: React.ReactNode;
	onBack?: boolean;
}

const style = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center'
}

const CustomTitle: React.FC<ICustomTitle> = ({ title, actions, onBack }) => {
	const navigate = useNavigate();
	const goBack = () => navigate(-1);
	return (
		<header style={style}>
			<div style={{ display: 'flex' }}>
				{onBack && (
					<ButtonIcon
						variant='ghost'
						size='md'
						icon={faArrowLeft}
						onClick={goBack}
						tooltip='Regresar'
					/>
				)}
				<Title level={2}>{title}</Title>
			</div>
			<div>
				<Space>{actions}</Space>
			</div>
		</header>
	)
};

export default CustomTitle;
