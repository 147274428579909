import { faFileExcel, faPlus } from "@fortawesome/free-solid-svg-icons";
import ButtonIcon from "../../components/ButtonComp";
import CustomTitle from "../../components/CustomTitle";
import  { useState } from "react";
import TableComp from "../../components/Table";
import PaginationComp from "../../components/PaginationComp";
import ModalAdd from "./ModalAdd";
import useNotification from "../../hooks/useNotification";
import useVouchers from "../../hooks/useVouchers";
import useChiefs from "../../hooks/useChiefs";
import ModalXlsx from "./ModalXlsx";


const VouchersMain = () => {
    const [modalAdd, setModalAdd] = useState < boolean > (false);
    const [modalXlsx, setModalXlsx] = useState < boolean > (false);
    const hookVouchers = useVouchers();
    const hookChiefs = useChiefs();
    const hookNotification = useNotification({ defaultPlacement: 'topRight' });

    return (
        <div>
            {hookNotification.contextHolder}
            <CustomTitle
                title={"Vales"}
                actions={[
                    <ButtonIcon name="Descargar reporte" icon={faFileExcel} variant="solid" color="green" onClick={() => setModalXlsx(true)} />,
                    <ButtonIcon name="Agregar vale" icon={faPlus} variant="solid" color="teal" onClick={() => setModalAdd(true)} />
                ]}
            />
            <TableComp
                headers={['#', 'Folio', 'Nombre de empleado','Turno', 'Motivo', 'Tiempo de comida', 'Fecha de registro', 'Autorizó', 'Fecha de creación', 'Acciones']}
                keys={['#', 'folio', 'chief.name', 'shift', 'reason', 'food_time', 'date', 'authorized', 'created_at']}
                body={hookVouchers.data}
                actions={[]}
                loading={hookVouchers.loading}
                loadingItems={hookVouchers.pageSize}
                page={hookVouchers.page}
                moment_dates={[{ index: 6, format: 'LL' }, { index: 8, format: 'LLL' }]}
            />
            <PaginationComp
                changePage={hookVouchers.changePage}
                page={hookVouchers.page}
                pageSize={hookVouchers.pageSize}
                totalItems={hookVouchers.totalItems}
            />

            {
                modalAdd &&
                <ModalAdd
                    onClose={() => setModalAdd(false)}
                    hookNotification={hookNotification}
                    hookVouchers={hookVouchers}
                    hookChiefs={hookChiefs}
                />
            }

{
                modalXlsx &&
                <ModalXlsx
                    onClose={() => setModalXlsx(false)}
                    hookNotification={hookNotification}
                />
            }
        </div>
    );
};

export default VouchersMain;
