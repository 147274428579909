import { Grid, theme } from "antd";
import { CSSProperties } from "react";

const { useToken } = theme;
const { useBreakpoint } = Grid;

const LoginStyles = () => {
    const { token } = useToken();
    const screens = useBreakpoint();

    const styles: { [key: string]: CSSProperties } = {
        section: {
            margin: 0,
            backgroundColor: token.colorBgContainer,
            height: screens.sm ? "100vh" : "auto",
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between'
        },
        container: {
            border: '1px solid #d9d9d9', // Color de borde más suave    
            borderRadius: '8px', // Bordes redondeados
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Sombra suave
            margin: "20px auto",
            padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
            width: '80%',
            height: "auto",
            transition: 'box-shadow 0.3s ease', // Transición suave para la sombra
            backgroundColor: '#fff',
            alignContent: 'center'
        },
        footer: {
            marginTop: token.marginLG,
            textAlign: "center",
            width: "100%"
        },
        forgotPassword: {
            float: "right"
        },
        header: {
            marginBottom: token.marginXL,
            textAlign: "center"
        },
        text: {
            color: token.colorTextSecondary
        },
        title: {
            fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
        },
        login: {
            width: "100%",
            objectFit: 'cover',
            height: '-webkit-fill-available'
        },
        logo: {
            width: "40%",
            height: "auto"
        },
        colForm: {
            display: 'flex',
            alignItems: 'center'
        },
        colImage: {
            height: '100vh',
            paddingRight: 0,
            paddingLeft: 0
        }
    };

    return {
        styles
    }
}

export default LoginStyles;