import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import usePagination from "./usePagination";
import { ITicket } from "../interfaces/model/Ticket";
import { RootState } from "../redux/store";
import { logDebug } from "../libs/logs";
import { setTickets } from "../redux/slices/appSlice";
import { indexTickets } from "../api/main/tickets";

const useTickets = () => {
	const ticketsStored = useSelector((state: RootState) => state.app.tickets);
	const dispatch = useDispatch();
	const hookTickets = usePagination<ITicket>({ pageSize: 20 });
	const { page, pageSize } = hookTickets;

	useEffect(() => {
		const getTickets = async () => {
			hookTickets.loadingStart();
			const { data, totalItems } = await indexTickets({ page, pageSize });
			dispatch(setTickets(data));
			hookTickets.setData(data);
			hookTickets.setTotalItems(totalItems);
			hookTickets.loadingEnd();
		};
		// if (ticketsStored) {
		// 	logDebug("Tickets cargados del store");
		// 	hookTickets.loadingStart();
		// 	hookTickets.setData(ticketsStored);
		// 	hookTickets.loadingEnd();
		// } else {
		// 	logDebug("Tickets cargados de la api");
		// }
		getTickets();
	}, [page, pageSize]);

	return hookTickets;
};

export default useTickets;
