import { BrowserRouter as Router } from 'react-router-dom';
import { AuthContextProvider } from './router/AuthContext';
import RoutesComp from './router/RoutesComp';

const App = () => {
	return (
		<Router>
			<AuthContextProvider>
				<RoutesComp />
			</AuthContextProvider>
		</Router>
	);
}

export default App;
