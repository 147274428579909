import { Route, Routes } from "react-router-dom"
import { Login } from "../pages/login/Index"
import { RequireAuth } from "./RequireAuth"
import Dashboard from "../pages/dashboard/Index"
import ForgotPassword from "../pages/login/ForgotPassword"
import Profile from "../pages/profile"
import VouchersMain from "../pages/vouchers/Index"
import TicketsMain from "../pages/tickets/Index"
import EmployeesMain from "../pages/employees/Index"

const RoutesComp = () => {
    return (
        <Routes>
            {/* Rutas de la página principal */}
            <Route path="/" element={<RequireAuth><Dashboard /></RequireAuth>} />
            <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>} />
            <Route path="/dashboards" element={<RequireAuth><Dashboard /></RequireAuth>} />
            <Route path="/tickets" element={<RequireAuth><TicketsMain /></RequireAuth>} />
            <Route path="/vouchers" element={<RequireAuth><VouchersMain /></RequireAuth>} />
            <Route path="/employees" element={<RequireAuth><EmployeesMain /></RequireAuth>} />
            {/* Rutas para la configuración como usuario raiz */}
            {/* Rutas públicas */}
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

        </Routes>
    )
}

export default RoutesComp