import { useState } from 'react';
import { IItem, IPaginationComponentProps, IPaginationResult } from '../interfaces/Pagination';

const usePagination = <ItemType extends IItem>({
    page: initialPage,
    pageSize: initialPageSize,
    totalItems: initialTotalItems
}: IPaginationComponentProps): IPaginationResult<ItemType> => {
    const [data, setData] = useState<ItemType[]>([]);
    const [selectedItem, setSelectedItem] = useState<ItemType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState<number>(initialPage ?? 1);
    const [pageSize, setPageSize] = useState<number>(initialPageSize ?? 10);
    const [totalItems, setTotalItems] = useState<number>(initialTotalItems ?? 0);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

    // Alternar entre ascendente y descendente
    const toggleSortOrder = () => {
        setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
        // Volver a la primera página al cambiar la dirección del orden
        setPage(1);
    }

    // Cambiar paginación
    const changePage = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
    };

    // Agregar elemento
    const addElement = (item: ItemType) => {
        if (page === 1)
            setData(prevState => {
                const updatedData = [item, ...prevState];

                // Si la longitud de los datos es mayor que el tamaño de la página, eliminar el último elemento
                if (updatedData.length > pageSize) {
                    updatedData.pop();
                }

                return updatedData;
            });
        setTotalItems(prevState => prevState + 1);
    }

    const addElementOld = (item: ItemType) => {
        setData(prevState => [item, ...prevState]);
        setTotalItems(prevState => prevState + 1);
    }

    // Actualizar elemento
    const updateElement = (item: ItemType) => {
        setData(prevState =>
            prevState.map(element =>
                (element.id === item.id ? item : element)
            )
        );
    }
    // Seleccionar elemento
    const selectItem = (item: ItemType) => {
        setSelectedItem(item);
    };

    // Limpiar selección
    const clearSelectedItem = () => {
        setSelectedItem(null);
    };

    const loadingStart = () => setLoading(true);
    const loadingEnd = () => setLoading(false);
    return {
        data,
        page,
        pageSize,
        totalItems,
        loading,
        setData,
        setPage,
        setPageSize,
        setTotalItems,
        loadingStart,
        loadingEnd,
        addElement,
        updateElement,
        changePage,
        sortOrder,
        toggleSortOrder,
        selectItem,
        selectedItem,
        clearSelectedItem
    };
};

export default usePagination;
