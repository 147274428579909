import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import i18next from 'i18next';
import { importAll } from './libs/languages';
import { ChakraProvider } from '@chakra-ui/react'
import 'bootstrap/dist/css/bootstrap.min.css';

import 'moment/locale/es';

require('moment-timezone');

/**
 * Crear un contexto que incluye todos los archivos .json 
 * dentro del directorio ./lang y sus subdirectorios.
 */
const langContext = (require as any).context('./lang', true, /\.json$/)

// Obtener el idioma almacenado en el almacenamiento local
const lang = localStorage.getItem('lang') ?? 'es';

// Inicializar i18next para las traducciones
i18next.init({
	// Configuración de interpolación para evitar problemas de seguridad
	interpolation: { escapeValue: false },

	// Idioma inicial obtenido del almacenamiento local o por defecto 'es'
	lng: lang,

	// Cargar dinámicamente todas las traducciones desde el directorio ./lang
	resources: importAll(langContext),
});

ReactDOM.render(
	<React.StrictMode>
		<ChakraProvider>
			<Provider store={store}>
				<App />
			</Provider>
		</ChakraProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

reportWebVitals();
