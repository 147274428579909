import { IChiefIndexParams, IChiefIndexResponse } from "../../interfaces/model/Chief";
import fetcher from "../../libs/fetcher";
import { logDebug } from "../../libs/logs";

export const indexChiefs = async ({ page, pageSize}: IChiefIndexParams): Promise<IChiefIndexResponse> => {
    const url = `/auth/chiefs`;
    let response: IChiefIndexResponse = { data: [], totalItems: 0 };
    let params: any = {page, pageSize};
    try {
        const res = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (res === undefined) {
            throw new Error('Request failed');
        }
        const { fetch } = res;
        logDebug("🚀 ~ indexChiefs ~ fetch:", fetch)
        if (typeof fetch === 'string') {
            // Manejar si fetch es cadena
        } else if (fetch instanceof Object) {
            if ('status' in fetch && fetch.status === 200) {
                const responseData = 'data' in fetch ? fetch.data : null;
                if (responseData) {
                    response = { data: responseData.data, totalItems: responseData.totalItems }
                }
            }
        }
        return response;
    } catch (error) {
        return response;
    }
}